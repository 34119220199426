//房源
<template>
  <div class="housing">
    <person-head></person-head>
    <div class="housing_box b-c-f">
      <div class="housing_head flexs p-l-20 p-r-20">
        <div class="housing_head_box flexs f-1 p-l-20">
          <div class="housing_head_txt">跟进类型:</div>
          <ul class="housing_head_ul flexs">
            <li
              @click="changeType(index)"
              v-for="(item, index) in 2"
              :key="index"
              :class="{ active: index + 1 == type }"
            >
              {{ index == 0 ? "楼栋号跟进" : "手机号跟进" }}
            </li>
          </ul>
        </div>
      </div>
      <ul class="housing_list">
        <li
          v-for="(item, index) in list"
          :key="index"
          :class="{ active: index % 2 != 0 }"
        >
          <div class="housing_list_mian">
            <div class="housing_list_ul f-1 flexs flex-wrap">
              <div class="housing_list_li flexs">
                <span>跟进时间：</span>
                <span>{{ item.createtime }}</span>
              </div>
              <div class="housing_list_li flexs">
                <span>房源信息：</span>
                <span>{{ item.house_name }}</span>
              </div>
              <div class="housing_list_li m-t-15 m-b-15 flexs">
                <span>小区地址：</span>
                <span>{{ item.fang_address }}</span>
              </div>
              <div class="housing_list_li flexs">
                <span>跟进内容：</span>
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="housing_list_mp3" v-if="type == 2">
            <img src="../../assets/image/personage/icon21.png" alt="" />
          </div> -->
        </li>
      </ul>
      <div class="paging" v-if="list.length != 0">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import PersonHead from "../../components/PersonHead";
export default {
  components: { PersonHead },
  data() {
    return {
      type: 1, //
      page: 1, //分页
      total: 0, //总页数
      list: [], //列表
    };
  },
  methods: {
    changePage (e) {
      this.page = e
      this.getgenJinLog()
    },
    //切换类型
    changeType(index) {
      this.type = index + 1;
      this.page = 1;
      this.list = []
      this.getgenJinLog();
    },
    getgenJinLog() {
      this.$axios
        .genJinLog({
          type: this.type,
          page: this.page,
        })
        .then((res) => {
          this.total = res.data.total
          this.list = res.data.data
        });
    },
  },
  created() {
    this.getgenJinLog();
  },
};
</script>

<style lang="less" scoped>
.housing_head_box {
  height: 75px;
  border-bottom: 1px solid #ebebeb;
  .housing_head_txt {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-right: 40px;
  }
  .housing_head_ul {
    li {
      cursor: pointer;
      color: #333333;
      font-size: 16px;
      margin-right: 40px;
    }
    .active {
      color: #3273f6;
      font-weight: bold;
    }
  }
}
.housing_list_mian {
  width: 880px;
}
.housing_list {
  li {
    display: flex;
    align-items: center;
    padding: 0 40px;
    height: 140px;
    .housing_list_li {
      width: 50%;
      &:nth-child(3),
      &:nth-child(4) {
        width: 100%;
      }
      span {
        color: #999999;
        font-size: 16px;
        &:last-child {
          color: #333333;
        }
      }
    }
  }
  .active {
    background: #f7fcff;
  }
}
</style>
